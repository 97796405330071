import { interfaces } from "~/api";
import { useSelector } from "~/store";
import { AgreementLayout, AgreementReader } from "@kenai/core";
import MapTextPartsToComponent from "../map-text-parts-to-component";

/** @deprecated TODO: replace this with AgreementReaderProps */
interface AgreementProps {
  onAgreementContinue?: () => void;
  onAgreementAcceptToggle?: (checked: boolean) => void;

  agreementDetails: interfaces["VISITOR_AGREEMENT"];
  entityConfig: interfaces["ENTITY_CONFIG"];
  mandatoryAgreementRead: boolean;
  headingText?: string;
  subHeadingText?: string;
  agreementLinkText?: string;
  disabled?: boolean;
  hideCompanyLogoOnLanding?: boolean;
}

const AGREEMENT_COOKIE = "kenai-visitor-app-agreement";

export default function Agreement(props: AgreementProps) {
  const entityAgreementIdentifier = useSelector(
    (state) => state.entityConfig.agreementIdentifier
  );
  const agreementValue = `${entityAgreementIdentifier}_${props.agreementDetails.AgreementVersion}`;

  return (
    <AgreementLayout>
      <AgreementReader
        dialogTitle="Visitors Agreement"
        headingText={
          props.headingText || "Welcome to our visitor pre-registration"
        }
        cookieName={AGREEMENT_COOKIE}
        identifier={agreementValue}
        companyLogo={
          !props.hideCompanyLogoOnLanding ? props.entityConfig.logo : undefined
        }
        companyName={props.entityConfig.entityName}
        mandatoryRead={props.mandatoryAgreementRead}
        agreementLinkText={props.agreementLinkText}
        onAgreementContinue={props.onAgreementContinue}
        onAgreementAcceptToggle={props.onAgreementAcceptToggle}
        disabled={props.disabled}
      >
        <MapTextPartsToComponent
          textParts={props.agreementDetails.agreementTextParts}
          replacements={[
            {
              regex: new RegExp("{{companyName}}", "g"),
              value: props.agreementDetails.companyName,
            },
          ]}
        />
      </AgreementReader>
    </AgreementLayout>
  );
}
